import {
  Stat,
  StatLabel,
  StatNumber,
  CardBody,
  Card,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  CardHeader,
  Box,
  Flex,
  Divider,
  HStack,
  Text
} from "@chakra-ui/react";
import './Home.scss';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { AgGridReact } from 'ag-grid-react';

import { useEffect, useState, useMemo } from "react";

import { ClientSideRowModelModule, AllCommunityModule, ModuleRegistry, themeQuartz } from 'ag-grid-community';

ModuleRegistry.registerModules([AllCommunityModule]);


const gridTheme = themeQuartz.withParams({
  /* Low spacing = very compact */
  spacing: 4,
  /* Changes the color of the grid text */
  /* Changes the color of the grid background */
  /* Changes the header color of the top row */
  headerBackgroundColor: 'rgb(158, 243, 240)',
  /* Changes the hover color of the row*/
  rowHoverColor: 'rgb(249, 241, 247)',
});

// Define the type for your row data
type RowData = {
  headerName: string;
  action: string;
  user: string;
  client: string;
  performed: string;
};

// Define the type for your filter
type Headers = {
  headerName: string;
  field: string;
  filter: boolean;
};

function NumberStat({ label, number }: { label: string, number: number }) {
  return (
    <Card minWidth={'20ch'}>
      <CardBody>
        <Stat>
          <StatLabel>{label}</StatLabel>
          <StatNumber>{number}</StatNumber>
        </Stat>
      </CardBody>
    </Card>
  )
}

function LastActions({ label, columns, data }: { label: string, columns: string[], data: any[] }) {
  return (
    <Card>
      <CardHeader>{label}</CardHeader>
      <CardBody>
        <TableContainer>
          <Table size='sm'>
            <Thead>
              <Tr>
                {columns.map(column => <Th key={column}>{column}</Th>)}
              </Tr>
            </Thead>
            <Tbody>
              {data.map(item => (
                <Tr key={item.id}>
                  {columns.map(column => (
                    <Td>{item[column]}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export function Home() {

  const [columns, setColumns] = useState<any>([]);
  const [jobsData, setJobsData] = useState<any>([])

  const { data: tenantData } = useMojoFetch("/api/v1/Clients/", "get");
  const clientCount = tenantData ? tenantData.length : 0;

  const { data: groupData } = useMojoFetch("/api/v1/Groups/", "get");
  const groupCount = groupData ? groupData.length : 0;

  const { data: userData } = useMojoFetch("/api/v1/Users/", "get");
  const userCount = userData ? userData.length : 0;

  const { data: partnerData } = useMojoFetch("/api/v1/Vendors/all/", "get");
  const partnerCount = partnerData ? partnerData.length : 0;

  const { data: contactData } = useMojoFetch("/api/v1/Contacts/all/", "get");
  const contactCount = contactData ? contactData.length : 0;

  const { data: integrationData } = useMojoFetch("/api/v1/Integrations/all/", "get");
  const integrationCount = integrationData ? integrationData.length : 0;


  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const { run: getJobsData } = useMojoEffect(
    `/api/v1/jobs`,
    'get'
  );


  const fetchJobsData = async () => {
    const [data, error] = await getJobsData();
    if (error) {
      return 
    }
    const headers = Object?.keys(data[0]) || []
    const columnHeaders = headers?.map((header) => {
      return { headerName: header, field: header, filter: true, floatingFilter: true, editable: true  }
    })
    setColumns(() => columnHeaders)
    setJobsData(() => data)
  }

  useEffect(() => {
    fetchJobsData();
  }, []);

  return (
    <Box className='home-page--wrapper'>
      <Flex className="home-page--header">
        <Flex className="home-header--left">
          <Heading className="home-heading">ADMIN HEALTH</Heading>
        </Flex>
      </Flex>
      <Flex className="home-page--body">
        <HStack className="home-card-stack">
          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Clients</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{clientCount}</Text>
              </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Contacts</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{contactCount}</Text>
              </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Groups</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{groupCount}</Text>
              </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Integrations</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{integrationCount}</Text>
              </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Users</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{userCount}</Text>
              </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
              <Flex className="card-heading">Vendors</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
              <Flex justify="center" align="center" height="100%">
                <Text>{partnerCount}</Text>
              </Flex>
            </CardBody>
          </Card>

        </HStack>

        <Box
          className="ag-theme-alpine"
          height="800px"
          width="100%"
          p="20px"
          borderRadius="8px"
          bg="#f8f8f8" // Optional background color for refinement
          overflow="hidden" // Optional: ensures content doesn't overflow
        >
          <AgGridReact
            theme={gridTheme}
            columnDefs={columns}
            rowData={jobsData}
            rowModelType="clientSide"
            rowSelection={  
              {
                mode: "multiRow",
                headerCheckbox: false
              }
            }
            modules={[ClientSideRowModelModule]}
            defaultColDef={defaultColDef}
            sideBar
          />
        </Box>

      </Flex>
    </Box>
  )
}