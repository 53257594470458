import { useParams } from 'react-router-dom';
import { Flex, Spinner, Center, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { components } from 'api/typesgen';
import { LocationMap } from '../../../components/LocationMap';
import { useMojoFetch } from 'api/useMojoFetch';
import './Info.scss';

export function ClientInfo() {
    const { tenantId } = useParams();
    const { data: client, isLoading } = useMojoFetch(
        `/api/v1/Clients/${tenantId}`,
        'get'
    );

    if (!tenantId || isLoading) {
        return (
            <Center h="100%">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (!client) {
        return (
            <Center h="100%">
                No client data available
            </Center>
        );
    }

    return (
        <Box className="client-info--tab">
            <Box className="client-info--map">
                <LocationMap 
                    lat={client.lat ?? ''}
                    lng={client.lng ?? ''}
                    height="100%"
                />
            </Box>
        </Box>

    );
}