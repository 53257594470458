interface TimezoneResponse {
    timeZoneId: string;
    timeZoneName: string;
    rawOffset: number;
    dstOffset: number;
    success: true;
}

interface TimezoneError {
    success: false;
    error: string;
}

type TimezoneResult = TimezoneResponse | TimezoneError;

/**
 * Fetches timezone information for a given latitude and longitude using Google Maps Timezone API
 * @param lat - Latitude of the location
 * @param lng - Longitude of the location
 * @returns Promise containing timezone information
 */
export const getTimezone = async (lat: number, lng: number): Promise<TimezoneResult> => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
        return {
            success: false,
            error: 'Google Maps API key is not configured'
        };
    }

    try {
        const timestamp = Math.floor(Date.now() / 1000);
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        
        if (!response.ok) {
            throw new Error('Failed to fetch timezone');
        }

        const data = await response.json();
        
        if (data.status !== 'OK') {
            throw new Error(data.errorMessage || 'Failed to get timezone data');
        }

        return {
            timeZoneId: data.timeZoneId,           // e.g., "America/New_York"
            timeZoneName: data.timeZoneName,       // e.g., "Eastern Daylight Time"
            rawOffset: data.rawOffset,             // The offset from UTC (in seconds) for the given location
            dstOffset: data.dstOffset,             // The offset for daylight-savings time (in seconds)
            success: true
        };
    } catch (error) {
        console.error('Error fetching timezone:', error);
        return {
            success: false,
            error: error instanceof Error ? error.message : 'An unknown error occurred'
        };
    }
}; 