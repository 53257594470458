import {
    FormLabel,
    FormControl,
    Input,
    Box,
    Switch,
    Flex,
    Button,
    useToast,
    Select,
} from '@chakra-ui/react';
//import { Select } from 'chakra-react-select';
import { ChangeEvent, useState } from 'react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useMojoEffect } from 'api/useMojoEffect';

export default function AddEditUserForm2(props) {
    const clientRoleOptions = props.rolesData
        ? props.rolesData.map(({ roleId, name }) => ({
            label: name,
            value: roleId,
        }))
        : [];
    //const [defaultRoleValue, setRoleDefaults] = useState('');
    const [key, setKey] = useState(1);
    //const [myActiveFlag, setFlag] = useState(true);
    const { run: runCreate } = useMojoEffect('/api/v1/users/simple', 'post');
    const { runWithId: getUser } = useMojoEffect(
        '/api/v1/users/findByEmail/email/',
        'get'
    );
    const [myEmail, setEmail] = useState('');
    const [isRunningCreate, setRunningCreate] = useState(false);
    const [userId, setUserId] = useState('');
    const initialData = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        TenantRole: '',
        password: '',
        activeFlag: true,
    };

    //const [values, setValues] = useState(initialData);

    const { register, handleSubmit, reset, setValue } = useForm({ defaultValues: initialData });
    const [isFormDisabled, disable] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const { run: linkUser } = useMojoEffect(`/api/v1/Users/link`, 'post');

    const [myActiveFlag, setFlag] = useState(true);

    const myReset = () => {
        disable(false);
        setUserId('');
        setEmailExists(false);
        setEmail('');
        reset(initialData);
        setKey(key + 1);
        setFlag(true);
    };

    const toast = useToast();

    async function submit(data) {
        setRunningCreate(true);

        if (!emailExists) {
            const userData = {
                UserId: '',
                firstName: data.firstName,
                lastName: data.lastName,
                email: myEmail,
                phone: data.phone,
                password: data.password,
                activeFlag: myActiveFlag,
                blockFlag: false,
                TenantRole: data.TenantRole,
            };

            const [createdData, error] = await runCreate({
                ...userData,
                TenantId: props.tenantId,
            });
            if (error === null) {
                props.refetch();
                myReset();
                toast({
                    title: 'User created',
                    status: 'success',
                });
            } else {
                myReset();
                toast({
                    title: 'User creation failed',
                    status: 'error',
                });
            }
        }
        else {
            const body = {
                UserId: userId,
                TenantId: props.tenantId,
                TenantRole: data.TenantRole,
                activeFlag: data.activeFlag,
            };
            const [linkdata, error] = await linkUser(body);
            if (error === null) {
                props.refetch();
                myReset();
                toast({
                    title: 'User linked',
                    status: 'success',
                });
            } else {
                myReset();
                toast({
                    title: 'User linking failed',
                    status: 'error',
                });
            }

        }

        setRunningCreate(false);
    }

    async function checkEmail(e: ChangeEvent<HTMLInputElement>) {
        const email = e.target.value.toLowerCase();
        setEmail(email);
        if (email.includes('@')) {
            const [userData, get_error] = await getUser(email);
            if (get_error == null) {
                let myRoles = [];
                if (userData.roles !== undefined && userData.roles['Clients'] !== undefined) {
                    myRoles = userData.roles['Clients'].filter(
                        (x) => x.entity_id === props.tenantId
                    );
                }
                if (myRoles.length > 0) {
                    toast({
                        title: 'Error',
                        description: 'User already linked',
                        status: 'error',
                    });
                    myReset();
                    return;
                }

                setEmailExists(true);
                //setValue('firstName', userData.firstName);
                //setValue('lastName', userData.lastName);
                //setValue('activeFlag', userData.activeFlag);            
                console.log(userData.activeFlag);
                reset({
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phone: userData.phone,
                    email: userData.email,
                    TenantRole: '',
                    password: '',
                    activeFlag: userData.activeFlag,
                });
                setFlag(userData.activeFlag);
                setUserId(userData.UserId);

                disable(true);
                toast({
                    title: 'Success',
                    description: 'User exists',
                    status: 'success',
                });
            } else {
                setEmailExists(false);
                if (
                    get_error != null &&
                    !get_error.message.toLowerCase().includes('not found')
                ) {
                    toast({
                        title: 'Error',
                        description: get_error.message,
                        status: 'error',
                    });
                }
            }
        } else {
            setEmailExists(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormControl display='flex' p={2}>
                <FormLabel w='10rem'>ADD NEW USER</FormLabel>
            </FormControl>
            <Box w='1000px' p={2}>
                <Flex>
                    <FormControl variant='floating' isRequired>
                        <Input
                            id='firstname'
                            type='text'
                            w='7rem'
                            size='xs'
                            mr='1em'
                            height='45px'
                            borderRadius='5'
                            {...register('firstName')}
                            disabled={isFormDisabled}
                        />
                        <FormLabel style={{ paddingRight: '8px' }}>First Name</FormLabel>
                    </FormControl>

                    <FormControl variant='floating' isRequired>
                        <Input
                            id='lastname'
                            type='text'
                            w='7rem'
                            size='xs'
                            height='45px'
                            borderRadius='5'
                            mr='1em'
                            {...register('lastName')}
                            disabled={isFormDisabled}
                        />
                        <FormLabel style={{ paddingRight: '8px' }}>Last Name</FormLabel>
                    </FormControl>

                    <FormControl variant='floating' isRequired>
                        <Input
                            type='email'
                            w='7rem'
                            size='xs'
                            height='45px'
                            borderRadius='5'
                            mr='1em'
                            {...register('email')}
                            onChange={(e) => checkEmail(e)}
                            disabled={isFormDisabled}
                        />
                        <FormLabel style={{ paddingRight: '8px' }}>Email</FormLabel>
                    </FormControl>

                    <FormControl variant='floating' isRequired>
                        <Input
                            type='text'
                            w='7rem'
                            size='xs'
                            height='45px'
                            borderRadius='5'
                            mr='1em'
                            {...register('phone')}
                            disabled={isFormDisabled}
                        />
                        <FormLabel style={{ paddingRight: '8px' }}>Phone</FormLabel>
                    </FormControl>
                    <FormControl variant='floating' isRequired>
                        <Input
                            id='pass1'
                            type='password'
                            w='7rem'
                            size='xs'
                            height='45px'
                            borderRadius='5'
                            mr='1em'
                            {...register('password')}
                            disabled={isFormDisabled}
                        />
                        <FormLabel style={{ paddingRight: '8px' }}>Password</FormLabel>
                    </FormControl>
                    <FormControl isRequired>
                        <Box w='160px' mr='1em'>
                            <Select
                                key={key}
                                placeholder='Role'
                                {...register('TenantRole')}
                                size='sm'
                                height='45px'
                            >
                                {clientRoleOptions.map((x) => (
                                    <option value={x.value}>{x.label}</option>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Flex justifyContent='center' alignItems='center' height='45px'>
                            <FormLabel mb='0px'>Active?</FormLabel>
                            <Switch
                                isChecked={myActiveFlag}
                                id='active'
                                size='md'
                                {...register('activeFlag')}
                                onChange={() => setFlag(!myActiveFlag)}
                            />
                        </Flex>
                    </FormControl>
                    <FormControl>
                        <Button
                            ml='30px'
                            leftIcon={<AddIcon />}
                            type='submit'
                            variant='mojoPrimary'
                            size='sm'
                            isLoading={isRunningCreate}
                        >
                            {emailExists ? 'UPDATE USER' : 'ADD NEW USER'}
                        </Button>
                        {!isRunningCreate && (
                            <Button
                                ml='30px'
                                leftIcon={<CloseIcon />}
                                variant='mojoPrimary'
                                onClick={(e) => myReset()}
                                size='sm'
                            >
                                CLEAR FORM
                            </Button>
                        )}
                    </FormControl>
                </Flex>
            </Box>
        </form>
    );
}
