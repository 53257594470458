import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { getTimezone } from '../utils/googleMaps';
import { useEffect, useRef } from 'react';

interface AddressData {
    address: string;
    lat: number;
    lng: number;
    city: string;
    state: string;
    zip: string;
    timezone?: {
        timeZoneId: string;
        timeZoneName: string;
        rawOffset: number;
        dstOffset: number;
    } | null;
}

interface AddressAutocompleteProps {
    control: any;
    name: any;
    label?: string;
    placeholder: any;
    isRequired?: boolean;
    onChange: (data: AddressData) => void;
    bg: any;
    color: any;
    className?: string;
}

export default function AddressAutocomplete({
    control,
    name,
    label,
    placeholder,
    isRequired,
    onChange,
    bg,
    color,
    className
}: AddressAutocompleteProps) {
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

    const initAutocomplete = (inputElement: HTMLInputElement | null) => {
        if (!inputElement || !window.google?.maps?.places) return;

        // Clear any existing autocomplete
        if (autocompleteRef.current) {
            google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }

        autocompleteRef.current = new google.maps.places.Autocomplete(inputElement, {
            componentRestrictions: { country: 'US' },
            fields: ['address_components', 'formatted_address', 'geometry']
        });

        autocompleteRef.current.addListener('place_changed', async () => {
            const place = autocompleteRef.current?.getPlace();
            if (!place?.geometry || !place.address_components) return;

            let streetNumber = '';
            let streetName = '';

            const addressData: AddressData = {
                address: '',
                lat: place.geometry.location?.lat() ?? 0,
                lng: place.geometry.location?.lng() ?? 0,
                city: '',
                state: '',
                zip: '',
                timezone: null
            };

            place.address_components.forEach(component => {
                const types = component.types;
                if (types.includes('street_number')) streetNumber = component.long_name;
                if (types.includes('route')) streetName = component.long_name;
                if (types.includes('locality')) addressData.city = component.long_name;
                if (types.includes('administrative_area_level_1')) addressData.state = component.short_name;
                if (types.includes('postal_code')) addressData.zip = component.long_name;
            });

            addressData.address = `${streetNumber} ${streetName}`.trim();

            if (addressData.lat && addressData.lng) {
                const timezoneData = await getTimezone(addressData.lat, addressData.lng);
                if (timezoneData.success) {
                    const { timeZoneId, timeZoneName, rawOffset, dstOffset } = timezoneData;
                    addressData.timezone = { timeZoneId, timeZoneName, rawOffset, dstOffset };
                }
            }

            if (onChange) {
                onChange(addressData);
            }
        });
    };

    // Cleanup listener on unmount
    useEffect(() => {
        return () => {
            if (autocompleteRef.current) {
                google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, []);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl variant='floating' isRequired={isRequired} className={className}>
                    <Input
                        {...field}
                        ref={(input) => {
                            if (input) initAutocomplete(input);
                        }}
                        height='45px'
                        bg={bg}
                        color={color}
                    />
                    <FormLabel style={{ paddingRight: '8px' }}>{placeholder || label}</FormLabel>
                </FormControl>
            )}
        />
    );
}