import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { useToast } from '@chakra-ui/react';
import { ClientForm } from '../ClientForm';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';

function ClientAddForm({ onlyRequired, clientTypesData, brandsData, clientGroupsData }) {
    const { run: runCreate } = useMojoEffect('/api/v1/Clients/create', 'post');

    const navigate = useNavigate();
    const toast = useToast();

    return (
        <ClientForm
            clientTypesData={clientTypesData}
            brandsData={brandsData}
            clientGroupsData={clientGroupsData}
            isLoading={false}
            isSubmitting={false}
            submit={async (data) => {
                const [postResult, error] = await runCreate({
                    ...data,
                });
                if (error === null) {
                    navigate(`/clients`);
                    toast({
                        title: 'Client created',
                        status: 'success',
                    });
                } else {
                    navigate(`/clients`);
                    toast({
                        title: 'Client creation failed',
                        status: 'error',
                    });
                }
                return postResult;
            }}
            defaultValues={{ activeFlag: true }}
            btnTitle='CREATE CLIENT'
            btnIcon={<AddIcon />}
            formTitle='ADD NEW CLIENT'
            onlyRequired={onlyRequired}
        />
    );
}

function ClientEditForm({
    onlyRequired,
    tenantId,
    clientTypesData,
    brandsData,
    clientGroupsData,
    clientData,
    onUpdateSuccess,
}) {
    const { run: runUpdate } = useMojoEffect(
        `/api/v1/Clients/${tenantId}`,
        'put'
    );

    const toast = useToast();

    const values = {
        ...clientData,
        TenantBrandIds: clientData?.TenantBrandIds || [],
    } as typeof clientData;

    return (
        <ClientForm
            clientTypesData={clientTypesData}
            brandsData={brandsData}
            clientGroupsData={clientGroupsData}
            isLoading={false}
            isSubmitting={false}
            submit={async (args) => {
                const [updatedData, error] = await runUpdate({
                    ...args,
                });
                if (error === null) {
                    toast({
                        title: 'Client updated',
                        status: 'success',
                    });
                    onUpdateSuccess?.();
                } else {
                    toast({
                        title: 'Client update failed',
                        status: 'error',
                    });
                }
                return updatedData;
            }}
            values={values}
            defaultValues={values}
            btnTitle='UPDATE CLIENT'
            btnIcon={<EditIcon />}
            formTitle={`UPDATE ${clientData?.name}`}
            onlyRequired={false}
        />
    );
}

export default function ClientAddEditForm({ tenantId, clientGroupsData, brandsData, clientTypesData, clientData, onUpdateSuccess }) {

    if (clientData === null || clientData === undefined) {
        return <></>;
    }

    if (tenantId) {
        return (
            <ClientEditForm
                onlyRequired={false}
                tenantId={tenantId}
                brandsData={brandsData}
                clientTypesData={clientTypesData}
                clientGroupsData={clientGroupsData}
                clientData={clientData}
                onUpdateSuccess={onUpdateSuccess}
            />
        );
    }
    return (
        <ClientAddForm
            onlyRequired={true}
            brandsData={brandsData}
            clientTypesData={clientTypesData}
            clientGroupsData={clientGroupsData}
        />
    );
}
