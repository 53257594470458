import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    useColorModeValue,
    Text
} from "@chakra-ui/react";
import { forwardRef } from "react";


export const FloatingLabelInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { placeholder, className, ...otherProps } = props;
    const bg = useColorModeValue("white.100", "black.100");
    const color = useColorModeValue("black.200", "white.100");

    return (
        <FormControl
            variant='floating'
            isInvalid={props.isInvalid}
            className={className}
            isRequired={
                otherProps.isRequired === undefined ? false : otherProps.isRequired
            }
        >
            <Input {...otherProps} ref={ref} height='45px' />
            {/* It is important that the Label comes after the Control due to css selectors */}
            <FormLabel bg={bg} style={{ paddingRight: '8px' }} color={color}>
                {placeholder}
            </FormLabel>
        </FormControl>
    );
})
