import { useEffect, useState } from 'react';
import {
    Box,
    useColorModeValue,
    Heading,
    Card,
    CardHeader,
    CardBody,
    Stack,
    Text,
    Button,
    Divider,
    Flex,
    Spinner,
    useColorMode
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import 'react-datepicker/dist/react-datepicker.css';

function InventoryWidget({ isActive, onClick, data, isLoading }) {
    const [selectedKpi, setSelectedKpi] = useState('');

    // light/dark mode
    const { colorMode } = useColorMode();
    const bg = useColorModeValue('white.100', '#282828');
    const cardBackground = useColorModeValue('white', '#282828');
    const axisColor = useColorModeValue('#43425D', 'white')
    const borderColor = useColorModeValue('gray.200', '#3f3f3f');
    const titleColor = useColorModeValue('black.500', 'white');
    const fontColor = useColorModeValue('gray.800', 'white')



    const [chartData, setChartData] = useState({
      series: [
        {
          name: 'New',
          data: data.dataSeries.map((x) => (x.new === undefined ? 0 : x.new)),
          type: 'column',
          color: '#73CEE2',
        },
        {
          name: 'Used',
          data: data.dataSeries.map((x) => (x.used === undefined ? 0 : x.used)),
          type: 'column',
          color: '#2F8DA2',
        },
        {
          name: 'Leads',
          data: data.dataSeries.map((x) =>
            x.leadsTotal === undefined ? 0 : x.leadsTotal
          ),
          type: 'column',
          color: '#04B4DB',
        },
        {
          name: 'New Leads',
          data: data.dataSeries.map((x) =>
            x.leadsNew === undefined ? 0 : x.leadsNew
          ),
          type: 'column',
          color: '#0276E4',
        },
        {
          name: 'Used Leads',
          data: data.dataSeries.map((x) =>
            x.leadsUsed === undefined ? 0 : x.leadsUsed
          ),
          type: 'column',
          color: '#1087BB',
        },
      ],
    });
    const [chartOption, setChartOption] = useState<ApexOptions>({
      title: {
        style: {
          color: titleColor,
        },
      },
      chart: {
        id: '_inventoryWidget',
        toolbar: {
          show: false,
        },
        width: '100%',
        background: bg,
      },
      plotOptions: {
        bar: {
          columnWidth: '70%',
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 3,
      },
      xaxis: {
        categories: data.categories,
        labels: {
          show: true,
          style: {
            colors: axisColor, //purple.500
            fontSize: '0.5625rem', //9px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max:
          Math.max(
            ...data.dataSeries
              .map((x) => (x.new === undefined ? 0 : x.new))
              .concat(
                data.dataSeries
                  .map((x) => (x.used === undefined ? 0 : x.used))
                  .concat(
                    data.dataSeries.map((x) =>
                      x.leadsTotal === undefined ? 0 : x.leadsTotal
                    )
                  )
              )
          ) === 0
            ? 10
            : Math.ceil(
                Math.max(
                  ...data.dataSeries
                    .map((x) => (x.new === undefined ? 0 : x.new))
                    .concat(
                      data.dataSeries
                        .map((x) => (x.used === undefined ? 0 : x.used))
                        .concat(
                          data.dataSeries.map((x) =>
                            x.leadsTotal === undefined ? 0 : x.leadsTotal
                          )
                        )
                    )
                ) / 4
              ) *
                4 +
              4,
        labels: {
          style: {
            colors: axisColor, //purple.500
            fontSize: '0.5625rem', //9px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
          formatter: function (value) {
            if (value) {
              return `${value.toLocaleString()}`;
            } else return '';
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: 0,
        offsetY: 20,
        fontSize: '8px',
        fontFamily: 'gothamMedium',
        fontWeight: 500,
        labels: {
          colors: fontColor,
        },
        itemMargin: {
          horizontal: 10,
        },
        markers: {
          strokeWidth: 1,
        },
      },
    });

    const handleClick = () => {
        onClick();
    };

    useEffect(() => {
        setChartOption((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                background: bg,
            },
            yaxis: Array.isArray(prevOptions.yaxis)
                ? prevOptions.yaxis.map(y => ({
                    ...y,
                    labels: {
                        ...y.labels,
                        style: {
                            ...y.labels?.style,
                            colors: axisColor,
                        },
                    },
                }))
                : {
                    ...prevOptions.yaxis,
                    labels: {
                        ...prevOptions.yaxis?.labels,
                        style: {
                            ...prevOptions.yaxis?.labels?.style,
                            colors: axisColor,
                        },
                    },
                },
            xaxis: {
                ...prevOptions.xaxis,
                labels: {
                    ...prevOptions.xaxis?.labels,
                    style: {
                        ...prevOptions.xaxis?.labels?.style,
                        colors: axisColor,
                    },
                },
            },
        }));
    }, [colorMode, bg, axisColor]);

    return (
        <Box
            className={`widget ${isActive ? 'active' : ''}`}
            onClick={handleClick}
            borderColor={'mojo.200'}
        >
            <Flex className='kpi-widget--container' bg={bg} borderColor={borderColor}>
                <Box className='kpi-widget-chart--wrap'>
                    <Heading className='kpi-widget--heading' color={titleColor}>
                        INVENTORY
                    </Heading>
                    <Flex className='kpi-widget--spinner'>
                        {isLoading ? (
                            <Spinner size='sm' />
                        ) : (
                            <Chart
                                className='kpi-widget--chart'
                                options={chartOption}
                                series={chartData.series}
                                type='line'
                                width='100%'
                                height='100%'
                            />
                        )}
                    </Flex>
                </Box>

                <Flex className='kpi-widget-card--wrap'>
                    <Card
                        className='kpi-widget--card'
                        bg={cardBackground}
                        borderColor={borderColor}
                    >
                        <CardHeader className='kpi-card--header'>
                            <Heading className='kpi-card--heading' color={fontColor}>
                                Avg Leads Per Inventory
                            </Heading>
                        </CardHeader>
                        <Divider />
                        <CardBody className='kpi-card--body'>
                            <Stack className='kpi-card--body-stack'>
                                <Box className='kpi-card--value kpi-card--pos-avg'>
                                    <Text>
                                        {data.avg_leads_per_invt !== null &&
                                            data.avg_leads_per_invt !== undefined
                                            ? data.avg_leads_per_invt
                                            : 'n/a'}
                                    </Text>
                                </Box>

                                <Box className='kpi-card--details'>
                                    <Box className='kpi-card--detail-item'>
                                        <Text textAlign='left' color={fontColor}>
                                            Nation
                                        </Text>
                                        <Text textAlign='right' color={fontColor}>
                                            $--
                                        </Text>
                                    </Box>
                                    <Box className='kpi-card--detail-item'>
                                        <Text textAlign='left' color={fontColor}>
                                            Brand
                                        </Text>
                                        <Text textAlign='right' color={fontColor}>
                                            $--
                                        </Text>
                                    </Box>
                                    <Box className='kpi-card--detail-item'>
                                        <Text textAlign='left' color={fontColor}>
                                            Similar Stores
                                        </Text>
                                        <Text textAlign='right' color={fontColor}>
                                            $--
                                        </Text>
                                    </Box>
                                </Box>
                                <Divider />
                                <Flex className="kpi-card--button">
                                    <Button
                                        color={'white.100'}
                                        fontSize={'xs8'}
                                        bgColor={'mojo.200'}
                                        p={'5px 2px'}
                                        h={'auto'}
                                    >
                                        VIEW FULL REPORT
                                    </Button>
                                </Flex>
                            </Stack>
                        </CardBody>
                    </Card>
                </Flex>
            </Flex>
        </Box>
    );
}

export default InventoryWidget;
