import { Box, Text } from '@chakra-ui/react';
import { Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { useState } from 'react';

interface LocationMapProps {
    lat: string | number;
    lng: string | number;
    height?: string;
}

const CustomMarker = ({ position }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <AdvancedMarker 
            position={position}
        >
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    cursor: 'pointer',
                    transform: 'translate(-50%, -100%)',
                    position: 'relative'
                }}
            >
                {isHovered && (
                    <div style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'white',
                        padding: '8px 12px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        whiteSpace: 'nowrap',
                        marginBottom: '8px',
                        zIndex: 1
                    }}>
                        <Text fontSize="sm" color="gray.800">
                            {`${position.lat.toFixed(6)}, ${position.lng.toFixed(6)}`}
                        </Text>
                    </div>
                )}
                <div style={{
                    width: '32px',
                    height: '32px',
                    backgroundColor: '#00A3C4',
                    borderRadius: '50% 50% 50% 0',
                    transform: 'rotate(-45deg)',
                    boxShadow: isHovered 
                        ? '0 4px 8px rgba(0,0,0,0.3)' 
                        : '0 2px 4px rgba(0,0,0,0.2)',
                    transition: 'all 0.2s ease-in-out',
                    border: '2px solid white'
                }}>
                    <div style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%) rotate(45deg)'
                    }} />
                </div>
            </div>
        </AdvancedMarker>
    );
};

export function LocationMap({ lat, lng, height = '500px' }: LocationMapProps) {
    const parsedLat = typeof lat === 'string' ? parseFloat(lat) : lat;
    const parsedLng = typeof lng === 'string' ? parseFloat(lng) : lng;

    const hasValidCoordinates = 
        lat !== undefined && 
        lat !== '' &&
        lng !== undefined && 
        lng !== '' &&
        !isNaN(parsedLat) && 
        !isNaN(parsedLng) && 
        Math.abs(parsedLat) <= 90 && 
        Math.abs(parsedLng) <= 180;

    const center = hasValidCoordinates ? {
        lat: parsedLat,
        lng: parsedLng
    } : { // US center if invalid
        lat: 39.8283,
        lng: -98.5795
    };

    return (
        <Box height={height} width="100%" position="relative">
            <Map
                mapId='70b9a60a00b9182a'
                defaultZoom={hasValidCoordinates ? 15 : 4}
                defaultCenter={center}
                minZoom={2}
                maxZoom={20}
                disableDefaultUI={false}
                mapTypeControl={true}
                streetViewControl={true}
                fullscreenControl={true}
                zoomControl={true}
                keyboardShortcuts={true}
                scrollwheel={true}
                style={{ 
                    width: '100%', 
                    height: '100%',
                }}
            >
                {hasValidCoordinates && (
                    <CustomMarker 
                        position={{
                            lat: parsedLat,
                            lng: parsedLng
                        }}
                    />
                )}
            </Map>
        </Box>
    );
} 