export const allTenantIntegrations = {
  googleService: {
    id: "googleService",
    name: "Google Service",
  },
  googleAds: {
    id: "googleAds",
    name: "Google Ads",
  },
  facebook: {
    id: "facebook",
    name: "Facebook",
  },
  vautoInventory: {
    id: "vAutoinventoryId",
    name: "VAuto Inventory",
  },
  driveCentricCRM: {
    id: "driveCentricCRMId",
    name: "DriveCentric CRM",
  },
  googleAnalytics: {
    id: "googleAnalytics",
    name: "Google Analytics",
  },
  rules: {
    id: "rules",
    name: "Rules",
  },
  eLeads: {
    id: "eleads",
    name: "E-Leads",
  },
};

export const allIntegrationStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const allIntegrationKeys = Object.values(allTenantIntegrations).map(
  ({ id }) => id
);

export type TenantIntegrationData = {
  name: String;
  id: String;
  status: String;
  value: any;
};
