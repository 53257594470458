import { Outlet, useLocation, useParams } from 'react-router-dom';
import ClientAddEditForm from '../AddEditClientForm';
import { EditorWithDetails } from 'layout/EditorWithDetails';
import { TabItemEnum } from "../ClientTabs/TabItemEnum";
import Tabs from "../ClientTabs/Tabs";
import { Button, Heading, Box } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';

import './ClientDetail.scss';
import { useState, useEffect } from 'react';
import { useMojoFetch } from 'api/useMojoFetch';

function toTabItem(value: string): TabItemEnum | null {
    const enumValues = Object.values(TabItemEnum);
    if (enumValues.includes(value as TabItemEnum)) {
        return value as TabItemEnum;
    }
    return null;
}

export function ClientDetail() {
    const { tenantId } = useParams();
    const [updateCounter, setUpdateCounter] = useState(0);

    const { isLoading: isGroupsDataLoading, data: clientGroupsData } =
        useMojoFetch('/api/v1/groups/', 'get');

    const { data: brandsData, isLoading: isBrandsDataLoading } = useMojoFetch(
        '/api/v1/Brands/all',
        'get'
    );
    const { data: clientTypesData, isLoading: isClientTypesDataLoading } =
        useMojoFetch('/api/v1/TenantTypes/all', 'get');
    const { data: clientData, isLoading, refetch: refetchClient } = useMojoFetch(
        `/api/v1/Clients/${tenantId}`,
        'get'
    );

    useEffect(() => {
        if (updateCounter > 0) {
            refetchClient();
        }
    }, [updateCounter, refetchClient]);

    const [hideForm, setHideForm] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const lastPart = pathname.split('/').at(-1);
    const currentTab = (lastPart && toTabItem(lastPart)) || TabItemEnum.Contacts;

    const handleUpdateSuccess = () => {
        setUpdateCounter(prev => prev + 1);
    };

    return (
        <Box>
            <EditorWithDetails style={{ paddingBottom: 0 }}>
                <div className='client-form-header'>
                    <Button
                        leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
                        variant='mojoDefault'
                        onClick={() => navigate('/clients')}
                    >
                        CLIENT LIST
                    </Button>
                    {!hideForm && (
                        <Heading size={'md'} style={{ marginRight: 'auto' }}>
                            {tenantId && 'UPDATE CLIENT'}
                            {!tenantId && 'ADD CLIENT'}
                        </Heading>
                    )}
                    <div
                        className='collapse-icon'
                        role='button'
                        onClick={() => setHideForm(!hideForm)}
                    >
                        <img
                            src={`/pages/clients/list/${hideForm ? 'open' : 'close'
                                }-arrows.svg`}
                            alt='arrow'
                        />
                    </div>
                    {hideForm && <Tabs currentTab={currentTab} />}
                </div>
                {hideForm ? <></> : <Tabs currentTab={currentTab} />}
            </EditorWithDetails>

            <EditorWithDetails hideLeftPanel={hideForm}>
                <ClientAddEditForm
                    tenantId={tenantId}
                    clientGroupsData={clientGroupsData}
                    brandsData={brandsData}
                    clientTypesData={clientTypesData}
                    clientData={clientData}
                    onUpdateSuccess={handleUpdateSuccess}
                />
                <Outlet />
            </EditorWithDetails>
        </Box>
    );
}