import { useEffect, useState } from 'react';
import {
  Select,
  HStack,
  Box,
  useColorModeValue,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  useDisclosure,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import moment, { Moment } from 'moment';
import WebsiteTrafficWidget from 'components/Widgets/WebsiteTrafficWidget';
import InventoryWidget from 'components/Widgets/InventoryWidget';
import SoldWidget from 'components/Widgets/SoldWidget';
import InvestmentChart from 'components/Charts/InvestmentChart';
import InventoryChart from 'components/Charts/InventoryChart';
import WebsiteTrafficChart from 'components/Charts/WebsiteTrafficChart';
import SoldChart from 'components/Charts/SoldChart';
import './dashboard-layout.scss';
import DateFilter from 'components/DateFilter/DateFilter';
import InvestmentWidget from 'components/Widgets/InvestmentWidget';
import { useReportEffect } from 'api/useRepoEffect';
import { useMojoEffect } from 'api/useMojoEffect';
import { connect } from 'react-redux';
import { useMojoFetch } from 'api/useMojoFetch';

function Dashboard(props) {
  const {
    isOpen: isAlertVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });
  const [key, setKey] = useState(1);

  const [startDate, setStartDate] = useState<Moment>(
    moment().startOf('month').subtract(6, 'month')
  );
  const [endDate, setEndDate] = useState<Moment>(
    moment().subtract(1, 'month').endOf('month')
  );
  const [dateKey, setDateKey] = useState(1);

  const { runWithId: getClients } = useMojoEffect(`/api/v1/clients?`, 'get');
  const { data: sourceTypeList } = useMojoFetch(`/api/v1/sourcetypes`, 'get');
  const [clients, setClients] = useState<any[]>([]);
  const [isFilterLoading, setFilterLoading] = useState(false);
  const [isClientListLoading, setClientListLoading] = useState(false);
  const { run: getInvestmentData } = useReportEffect('/investments', 'post');
  const { run: getInventoryData } = useReportEffect('/inventory', 'post');
  const { run: getTrafficData } = useReportEffect('/websitemetrics', 'post');
  const { run: getSalesData } = useReportEffect('/sales', 'post');

  const [investmentSeries, setInvestmentSeries] = useState<{
    categories: string[];
    dataSeries: any[];
    avg_cost_per_lead: Number | null;
  }>({ categories: [], dataSeries: [], avg_cost_per_lead: null });
  const [inventorySeries, setInventorySeries] = useState<{
    categories: string[];
    dataSeries: any[];
    avg_leads_per_invt: Number;
  }>({ categories: [], dataSeries: [], avg_leads_per_invt: 0 });
  const [trafficSeries, setTrafficSeries] = useState<{
    categories: string[];
    dataSeries: any[];
    avg_leads_per_visitor: Number;
  }>({ categories: [], dataSeries: [], avg_leads_per_visitor: 0 });
  const [salesSeries, setSalesSeries] = useState<{
    categories: string[];
    dataSeries: any[];
    avg_sold_rate: Number;
  }>({ categories: [], dataSeries: [], avg_sold_rate: 0 });

  const [clientId, setClientId] = useState(() => {
    return localStorage.getItem('location') || 'All';
  });
  const [stockType, setStockType] = useState(() => {
    return localStorage.getItem('stockType') || 'Total';
  });
  const [sourceType, setSourceType] = useState(() => {
    return localStorage.getItem('sourceType') || 'All';
  });

  const [interval, setInterval] = useState('month');
  const [activeWidgetState, setActiveWidget] = useState('InventoryWidget');

  useEffect(() => {
    localStorage.setItem('location', clientId);
    localStorage.setItem('stockType', stockType);
    localStorage.setItem('sourceType', sourceType);
  }, [clientId, stockType, sourceType]);

  useEffect(() => {
    const fetch = async () => {
      setClientListLoading(true);
      const [data, error] = await getClients(`groupId=${props.groupId}`);
      if (error === null) {
        const myTenant = data.find((x) => x.TenantId === clientId);
        if (myTenant === undefined) {
          setInvestmentSeries({
            categories: [],
            dataSeries: [],
            avg_cost_per_lead: null,
          });
          setInventorySeries({
            categories: [],
            dataSeries: [],
            avg_leads_per_invt: 0,
          });
          setTrafficSeries({
            categories: [],
            dataSeries: [],
            avg_leads_per_visitor: 0,
          });
          setSalesSeries({
            categories: [],
            dataSeries: [],
            avg_sold_rate: 0,
          });
          setClientId('');
          setKey(key + 1);
        }
        setClients(data);
      } else {
        //setError(error);
      }
      await doEverything(
        clientId,
        startDate,
        endDate,
        stockType,
        sourceType,
        interval
      );
      setClientListLoading(false);
    };
    fetch();
  }, [props.groupId]);

  const handleWidgetClick = (widgetName: string) => {
    setActiveWidget(widgetName);
  };

  async function handleClientChange(e) {
    setFilterLoading(true);
    if (e.target.value === '') {
      setInvestmentSeries({
        categories: [],
        dataSeries: [],
        avg_cost_per_lead: null,
      });
      setInventorySeries({
        categories: [],
        dataSeries: [],
        avg_leads_per_invt: 0,
      });
      setTrafficSeries({
        categories: [],
        dataSeries: [],
        avg_leads_per_visitor: 0,
      });
      setSalesSeries({
        categories: [],
        dataSeries: [],
        avg_sold_rate: 0,
      });
      setClientId('');
      localStorage.setItem('location', '');
      setKey(key + 1);
      setFilterLoading(false);
      onClose();
      return;
    }
    const client = clients.find((c) => c.TenantId === e.target.value);
    if (client !== undefined && client.TenantId !== clientId) {
      setClientId(client.TenantId);

      localStorage.setItem('location', client.TenantId);
      localStorage.setItem('location_name', client.name || '');

      await doEverything(
        client.TenantId,
        startDate,
        endDate,
        stockType,
        sourceType,
        interval
      );
    }
    setFilterLoading(false);
  }

  const handleIntervalChange = async (e) => {
    if (e.target.value === '') {
      e.target.value = 'month';
    }

    if (e.target.value === interval) {
      return;
    }

    await handleIntervalChangeBody(e.target.value, startDate, endDate);
  };

  const handleIntervalChangeBody = async (
    my_interval,
    start_date,
    end_date,   
  ) => {
    if (!start_date.isValid() || !end_date.isValid()) {
      return;
    }
    setInterval(my_interval);
    setFilterLoading(true);
    let sDate = start_date.clone();
    let eDate = end_date.clone();
    switch (my_interval) {
      case 'day':
        sDate = sDate.startOf('day');
        eDate = eDate.startOf('day');
        break;
      case 'month':
        sDate = sDate.startOf('month');
        eDate = eDate.endOf('month');
        break;
      case 'quarter':
        sDate = quarterStart(sDate);
        eDate = quarterEnd(eDate);
        break;
      case 'year':
        sDate = sDate.startOf('year');
        eDate = eDate.endOf('year');
        break;
    }

    setDateKey(dateKey + 1);
    setStartDate(sDate);
    setEndDate(eDate);

    await doEverything(
      clientId,
      sDate,
      eDate,
      stockType,
      sourceType,
      my_interval
    );
    setFilterLoading(false);
  };

  const quarterStart = (sDate: Moment): Moment => {
    const monthIndex = sDate.month() % 3; // get place of the month in the quarter
    return sDate.clone().startOf('month').subtract(monthIndex, 'month');
  };

  const quarterEnd = (eDate: Moment): Moment => {
    return quarterStart(eDate).add(2, 'month').endOf('month');
  };

  const handleStockTypeChange = async (e) => {
    setStockType(e.target.value);
    setFilterLoading(true);
    /*
    await doEverything(
      clientId,
      startDate,
      endDate,
      e.target.value,
      sourceType,
      interval
    );
    */
    setFilterLoading(false);
  };

  const handleSourceTypeChange = async (e) => {
    setSourceType(e.target.value);
    setFilterLoading(true);
    await doEverything(
      clientId,
      startDate,
      endDate,
      stockType,
      e.target.value,
      interval
    );
    setFilterLoading(false);
  };

  async function handleDateChange(range) {
    const [start_date, end_date] = range;
    await handleIntervalChangeBody(
      interval,
      moment(start_date),
      moment(end_date),
    );
  }

  async function doEverything(
    client_id,
    s,
    e,
    stock_type,
    source_type,
    my_interval
  ) {
    onClose();

    if (client_id === undefined || client_id === 'All') {
      return;
    }

    const postBody = {
      filters: {
        tenants: [client_id],
        startDate: s.format('YYYY-MM-DD'),
        endDate: e.format('YYYY-MM-DD'),
        sourceType: source_type,
        interval: my_interval,
      },
    };

    setFilterLoading(true);
    const promise = Promise.all([
      getInvestmentData(postBody),
      getInventoryData(postBody),
      getSalesData(postBody),
      getTrafficData(postBody),
    ]);

    const result = await promise;
    const investmentData = result[0][0];
    const inventoryData = result[1][0];
    const salesData = result[2][0];
    const trafficData = result[3][0];

    if (
      inventoryData === null ||
      inventoryData === undefined ||
      inventoryData === null ||
      inventoryData.length === 0
    ) {
      onOpen();
    } else {
      onClose();
    }

    let dates: Moment[] = [];
    for (
      let date = s;
      date < e.clone().add(1, my_interval).startOf(my_interval);
      date = date.clone().add(1, my_interval)
    ) {
      dates.push(date);
    }

    setInvestmentSeries({
      ...adjustDataV2(investmentData.data, dates, my_interval),
      avg_cost_per_lead: investmentData.avg_cost_per_lead,
    });

    setInventorySeries({
      ...adjustDataV2(inventoryData.data, dates, my_interval),
      avg_leads_per_invt: inventoryData.avg_leads_per_invt,
    });

    setSalesSeries({
      ...adjustDataV2(salesData.data, dates, my_interval),
      avg_sold_rate: salesData.avg_sold_rate,
    });
    setTrafficSeries({
      ...adjustDataV2(trafficData.data, dates, my_interval),
      avg_leads_per_visitor: trafficData.avg_leads_per_visitor,
    });
    setFilterLoading(false);
    setKey(key + 1);
  }

  function adjustDataV2(data: any[], dates: Moment[], my_interval: string) {
    const result = { categories: [] as string[], dataSeries: [] as any[] };
    if (data === undefined || data.length === 0) {
      // e.g. HTTP error
      return result;
    }

    dates.forEach((date, i) => {
      let node;
      switch (my_interval) {
        case 'day':
          node = data.find(
            (x) =>
              x.year === date.year() &&
              x.month === date.month() &&
              x.day === date.date()
          );
          break;
        case 'month':
          node = data.find(
            (x) => x.year === date.year() && x.month === date.month()
          );
          break;
        case 'quarter':
          node = data.find(
            (x) => x.year === date.year() && x.quarter === date.quarter()
          );
          break;
        case 'year':
          node = data.find((x) => x.year === date.year());
          break;
      }

      if (node) {
        result.dataSeries.push(node);
        result.categories.push(getDateLabel(date, my_interval));
      } else if (my_interval !== 'day' && !node) {
        result.dataSeries.push({
          total: undefined,
          new: undefined,
          used: undefined,
          budget: undefined,
        });
        result.categories.push(getDateLabel(date, my_interval));
      }
    });

    return result;
  }

  function getDateLabel(date, my_interval: string): string {
    switch (my_interval) {
      case 'day':
        return date.format('M/D/YYYY');
      case 'month':
        return date.format('M/YYYY');
      case 'quarter':
        return `Q${date.quarter()} ${date.year()}`;
      case 'year':
        return date.format('YYYY');
      default:
        return date.format('M/D/YYYY');
    }
  }
  const bg = useColorModeValue('white.100', '#121212');
  const filterBg = useColorModeValue('white', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', '#3f3f3f');

  return (
    <>
      {isAlertVisible && (
        <Alert status='error' mb='2em'>
          <Flex>
            <AlertIcon />
            <AlertTitle>Inventory data unavailable!</AlertTitle>
            <AlertDescription>
              Please use different dates or try again in a couple of days.
            </AlertDescription>
          </Flex>
          <Spacer />
          <CloseButton onClick={onClose} />
        </Alert>
      )}
      <Box className='dashboard-container' bg={bg}>
        <Box className='dashboard-header'>
          <Heading className='dashboard-heading' color={fontColor}>
            HOME DASHBOARD
          </Heading>
          <HStack className='dashboard-filters' color={fontColor}>
            <Flex className='spinner-container'>
              <Select
                isDisabled={isClientListLoading || isFilterLoading}
                value={clientId}
                className='dashboard-filter dashboard-filter-company'
                h='2.5rem'
                w='18.75rem'
                bg={filterBg}
                placeholder='Company Filter'
                border={borderColor}
                _placeholder={{ color: fontColor, opacity: 1 }}
                onChange={(e) => handleClientChange(e)}
              >
                {clients.map((client) => (
                  <option key={client.TenantId} value={client.TenantId}>
                    {client.name}
                  </option>
                ))}
              </Select>
            </Flex>
            <Select
              isDisabled
              defaultValue={stockType}
              className='dashboard-filter dashboard-filter-stock-type'
              h='2.5rem'
              w='12rem'
              bg={filterBg}
              placeholder='Stock Type Filter'
              onChange={handleStockTypeChange}
            >
              <option key='1' value='Total'>
                Total
              </option>
              <option key='2' value='New'>
                New
              </option>
              <option key='3' value='Used'>
                Used
              </option>
            </Select>
            <Select
              defaultValue={sourceType}
              className='dashboard-filter dashboard-filter-source-type'
              h='2.5rem'
              w='18.75rem'
              bg={filterBg}
              placeholder='Source Type Filter'
              onChange={handleSourceTypeChange}
            >
              <option key='0' value='All'>
                All
              </option>
              {sourceTypeList?.map((t) => (
                <option key={t.id} value={t.description}>
                  {t.description}
                </option>
              ))}
            </Select>
            <DateFilter
              key={dateKey}
              startDate={startDate}
              endDate={endDate}
              handleDateChange={handleDateChange}
            />
            <Select
              defaultValue={interval}
              className='dashboard-filter dashboard-filter-interval'
              h='2.5rem'
              w='8rem'
              bg={filterBg}
              placeholder='Interval'
              onChange={handleIntervalChange}
            >
              <option key='1' value='day'>
                Day
              </option>
              <option key='2' value='month'>
                Month
              </option>
              <option key='3' value='quarter'>
                Quarter
              </option>
              <option key='4' value='year'>
                Year
              </option>
            </Select>
          </HStack>
        </Box>
        <Flex className='dashboard-layout'>
          <Flex className='widget-column'>
            <InventoryWidget
              key={key}
              data={inventorySeries}
              isActive={activeWidgetState === 'InventoryWidget'}
              onClick={() => handleWidgetClick('InventoryWidget')}
              isLoading={isFilterLoading}
            />
            <InvestmentWidget
              key={key + 1}
              data={investmentSeries}
              isActive={activeWidgetState === 'InvestmentWidget'}
              onClick={() => handleWidgetClick('InvestmentWidget')}
              isLoading={isFilterLoading}
            />
            <WebsiteTrafficWidget
              key={key + 2}
              data={trafficSeries}
              isActive={activeWidgetState === 'WebsiteTrafficWidget'}
              onClick={() => handleWidgetClick('WebsiteTrafficWidget')}
              isLoading={isFilterLoading}
            />
            <SoldWidget
              key={key + 3}
              data={salesSeries}
              isActive={activeWidgetState === 'SoldWidget'}
              onClick={() => handleWidgetClick('SoldWidget')}
              isLoading={isFilterLoading}
            />
          </Flex>
          <Flex className='chart-column'>
            {activeWidgetState === 'InventoryWidget' && (
              <InventoryChart
                data={inventorySeries}
                key={key + 4}
                isLoading={isFilterLoading}
              />
            )}
            {activeWidgetState === 'InvestmentWidget' && (
              <InvestmentChart
                data={investmentSeries}
                key={key + 5}
                isLoading={isFilterLoading}
              />
            )}
            {activeWidgetState === 'WebsiteTrafficWidget' && (
              <WebsiteTrafficChart
                data={trafficSeries}
                key={key + 6}
                isLoading={isFilterLoading}
              />
            )}
            {activeWidgetState === 'SoldWidget' && (
              <SoldChart
                data={salesSeries}
                key={key + 7}
                isLoading={isFilterLoading}
              />
            )}
            {/*{activeWidgetState === 'ServiceRetentionWidget' && (
                            <ServiceRetentionChart
                                key={key + 8}
                                isLoading={isFilterLoading}
                            />
                        )} */}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(Dashboard);
